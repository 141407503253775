import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { MA_APP_IDS } from '@wix/members-area-integration-kit';
import type { EditorPlatformApp } from '@wix/platform-editor-sdk';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import { handleBlogInstallTimeout } from './editor/handle-blog-install-timeout';
import { blogAppDefId } from './external/common/constants/apps';
import type { EditorEvent } from './external/common/constants/events';
import { getAssetsUrl } from './external/common/services/editor-sdk';
import {
  handleBlogInstalled,
  handleEditorReady,
  handleGetAppManifest,
  handleMigrateAction,
  handleOnEvent,
  untilTimeout,
} from './external/common/services/handlers';
import { getMetaSiteId } from './external/common/services/instance';
import maIntegrationKit from './external/common/services/ma-integration-kit';
import monitoring from './external/common/services/monitoring';
import type { EditorActions } from './types/editor-actions.type';
import type { EditorAppContext } from './types/editor-app-context.type';

const editorApp = maIntegrationKit.withMembersArea(createEditorApp(), {
  installAutomatically: false,
  installAppsAutomatically: false,
  membersAreaApps: [
    MA_APP_IDS.ABOUT,
    MA_APP_IDS.FOLLOWERS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SETTINGS,
  ],
});

interface BlogEditorAppInterface
  extends Omit<EditorPlatformApp, 'editorReady'> {
  editorReady: EditorReadyFn;
}

export function createEditorApp(): Required<BlogEditorAppInterface> {
  let context: EditorAppContext | undefined;

  const handleAction: EditorPlatformApp['handleAction'] = (
    { type, payload }: EditorActions,
    sdk,
  ) => {
    if (!context) {
      return;
    }

    switch (type) {
      case 'appInstalled': {
        if (payload.appDefinitionId !== blogAppDefId) {
          break;
        }

        return monitoring.toMonitored(
          'handle-blog-installed',
          handleBlogInstalled(context),
        );
      }
      case 'migrate':
        return handleMigrateAction({ ...context, sdk }, payload.migrationId);
    }
  };

  return {
    editorReady: async (sdk, appToken, options, flowAPI) => {
      try {
        const instance = options.initialAppData.instance;
        const instanceId = options.initialAppData.instanceId;
        const metaSiteId = getMetaSiteId(instance)!;
        const origin = options.origin.type;

        monitoring.init({
          fedopsLoggerFactory: options.essentials.fedopsLoggerFactory,
          metaSiteId,
          instanceId,
        });

        type OriginInfo = { installMembersArea?: boolean } | undefined;

        context = {
          sdk,
          appToken,
          isADI: origin === 'ADI' || origin === 'ADI_MA',
          isClassicEditor: origin === 'CLASSIC',
          isEditorX: origin === 'RESPONSIVE',
          installMembersArea: (options.origin.info as OriginInfo)
            ?.installMembersArea,
          instance,
          essentials: options.essentials,
          assetsUrl: getAssetsUrl(options),
          biData: options.biData,
          flowAPI,
          silentInstallation: options.silentInstallation ?? false,
          origin: options.origin,
          editorType: origin,
        };

        return monitoring.toMonitored(
          'editor-ready',
          monitoring.toMonitored(
            'editor-ready-duration',
            (async () => {
              if (!context) {
                return;
              }

              await handleEditorReady({
                context,
                firstInstall: options.firstInstall,
              });

              console.log(
                '[Blog] Platform app is up',
                ...(process.env.NODE_ENV === 'development'
                  ? [{ options, context }]
                  : []),
              );
            })(),
            true,
            120000,
          ),
        );
      } catch (error) {
        console.log('BLOG INSTALLATION FAILED WITH ERROR:', error);
      }
    },
    handleAction,
    onEvent: (event) => {
      if (!context) {
        return;
      }

      return handleOnEvent(event as EditorEvent, context);
    },
    getAppManifest: async (options) => {
      if (!context) {
        return {};
      }

      const widgetStageData =
        context.flowAPI.experiments.enabled(EXPERIMENTS.SPLIT_POST_PAGE) &&
        context.isClassicEditor &&
        !context.isADI
          ? await fetchWidgetsStageData(options as any)
          : {};

      return { ...widgetStageData, ...handleGetAppManifest(context) };
    },
    exports: (editorSDK) => ({
      editor: {
        async appInstalled(data) {
          const handleActionTimedOut = await untilTimeout(
            async () =>
              editorApp?.handleAction?.(
                {
                  type: 'appInstalled',
                  payload: {
                    appDefinitionId: data.appDefinitionId,
                  },
                },
                editorSDK,
              ),
            `handleAction on appInstalled (appDefinitionId: ${data.appDefinitionId}) is stuck`,
            60000,
          );

          if (handleActionTimedOut) {
            await handleBlogInstallTimeout(context!);
          }
        },
        removeApp() {
          return editorApp?.handleAction?.(
            { type: 'removeApp', payload: {} },
            editorSDK,
          );
        },
      },
    }),
  };
}

export const editorReady = editorApp.editorReady;
export const handleAction = editorApp.handleAction;
export const onEvent = editorApp.onEvent;
export const getAppManifest = editorApp.getAppManifest;
export const exports = editorApp.exports;
